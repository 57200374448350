/* import __COLOCATED_TEMPLATE__ from './filter.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { type EntityType } from 'embercom/models/data/entity-types';
import { KNOWLEDGE_BLANK_QUERY_PARAMS } from 'embercom/lib/knowledge-hub/list-api';

interface Args {
  contentType: EntityType | EntityType[];
}

interface Signature {
  Args: Args;
  Blocks: {
    default: [];
  };
  Element: Element;
}

export default class Filter extends Component<Signature> {
  get queryParamsForLibraryRedirect() {
    return {
      // It's important to reset all query parameters in case of pre-existing filters for the page
      // we're going to transition the user to (as Ember.js uses sticky parameters).
      ...KNOWLEDGE_BLANK_QUERY_PARAMS,
      types: this.args.contentType,
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FinAiAgent::Knowledge::ContentLink::Filter': typeof Filter;
    'fin-ai-agent/knowledge/content-link/filter': typeof Filter;
  }
}
