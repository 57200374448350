/* import __COLOCATED_TEMPLATE__ from './base-go-further-card.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import templateOnlyComponent from '@ember/component/template-only';

export interface Args {
  style: 'light' | 'dark';
  heading: string;
  body: string;
}

interface Signature {
  Args: Args;
  Blocks: any;
}

const BaseGoFurtherCard = templateOnlyComponent<Signature>();
export default BaseGoFurtherCard;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ExpiredSub::GoFurther::BaseGoFurtherCard': typeof BaseGoFurtherCard;
    'expired-sub/go-further/base-go-further-card': typeof BaseGoFurtherCard;
  }
}
