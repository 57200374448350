/* import __COLOCATED_TEMPLATE__ from './content-status-icon.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import Component from '@glimmer/component';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Signature {
  Args: {
    status: string | undefined;
  };
}

export default class ContentStatusIcon extends Component<Signature> {
  get icon() {
    if (!this.args.status) {
      return 'lead';
    }

    return ({
      empty: 'lead',
      active: 'check',
      deleted: 'error',
      deactivated: 'error',
      pending: 'sync',
      started: 'sync',
      completed: 'check',
      failed: 'error',
      ingested: 'check',
      processing: 'sync',
    }[this.args.status] || 'check') as InterfaceIconName;
  }

  get iconColor() {
    return (
      {
        check: 'green',
        sync: 'yellow',
        error: 'red',
        lead: 'gray',
      }[this.icon as string] || 'green'
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FinAiAgent::Knowledge::ContentStatusIcon': typeof ContentStatusIcon;
  }
}
