/* import __COLOCATED_TEMPLATE__ from './content.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type ContentImportService from 'embercom/services/content-import-service';
import { EntityType } from 'embercom/models/data/entity-types';
import { type SyncSourceWrapperResponse } from 'embercom/services/knowledge-hub-service';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import type ContentImportSource from 'embercom/models/content-service/content-import-source';

export default class Content extends Component {
  @service declare contentImportService: ContentImportService;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare store: Store;
  articleEntityType: EntityType = EntityType.ArticleContent;
  externalContentEntityType: EntityType = EntityType.ExternalContent;
  snippetEntityType: EntityType = EntityType.ContentSnippet;
  fileEntityType: EntityType = EntityType.FileSourceContent;

  allContentTypesApplicableToFin = [
    this.articleEntityType,
    this.externalContentEntityType,
    this.snippetEntityType,
    this.fileEntityType,
  ];

  private getFolderIdOfFirstMatchingSource(
    contentType: EntityType,
    matcher: (source: SyncSourceWrapperResponse) => boolean,
  ): number | undefined {
    let matchingSource = this.findSyncSources(contentType, matcher);

    return matchingSource?.folder_id;
  }

  private findSyncSources(
    contentType: EntityType,
    matcher: (source: SyncSourceWrapperResponse) => boolean,
  ): SyncSourceWrapperResponse | undefined {
    return this.findUsageSummary(contentType)?.sync_sources.find(matcher);
  }

  private findUsageSummary(contentType: EntityType): any | undefined {
    return this.knowledgeHubService.usageSummary[contentType];
  }

  get getZendeskFolderId(): number | undefined {
    return this.getFolderIdOfFirstMatchingSource(
      this.articleEntityType,
      ({ source_type }: { source_type: string }) => source_type === 'zendesk',
    );
  }

  getExternalContentFolderId = (source: ContentImportSource): number | undefined => {
    return this.getFolderIdOfFirstMatchingSource(
      this.externalContentEntityType,
      ({ source_id }: { source_id: number }) => source_id === Number(source.id),
    );
  };

  get totalArticlesCount() {
    return (this.findUsageSummary(EntityType.ArticleContent)?.all || 0) as number;
  }

  get enabledArticlesCount() {
    return (this.findUsageSummary(EntityType.ArticleContent)?.agent || 0) as number;
  }

  get totalSnippetsCount() {
    return (this.findUsageSummary(EntityType.ContentSnippet)?.all || 0) as number;
  }

  get enabledSnippetsCount() {
    return (this.findUsageSummary(EntityType.ContentSnippet)?.agent || 0) as number;
  }

  get totalPdfsCount() {
    return (this.findUsageSummary(EntityType.FileSourceContent)?.all || 0) as number;
  }

  get enabledPdfsCount() {
    return (this.findUsageSummary(EntityType.FileSourceContent)?.agent || 0) as number;
  }

  get totalEnabledContentCount() {
    return (
      this.enabledArticlesCount +
      this.enabledSnippetsCount +
      this.enabledPdfsCount +
      this.enabledExternalContentCount
    );
  }

  get totalContentCount() {
    return (
      this.totalArticlesCount +
      this.totalSnippetsCount +
      this.totalPdfsCount +
      this.totalExternalContentCount
    );
  }

  get enabledExternalContentCount() {
    return (this.findUsageSummary(EntityType.ExternalContent)?.agent || 0) as number;
  }

  get totalExternalContentCount() {
    return (this.findUsageSummary(EntityType.ExternalContent)?.all || 0) as number;
  }

  get externalContentSources() {
    return this.contentImportService.contentImportSources ?? [];
  }

  get zendeskSyncSource() {
    return this.findSyncSources(
      this.articleEntityType,
      ({ source_type }: { source_type: string }) => source_type === 'zendesk',
    );
  }

  get zendeskSyncSourceWrapper() {
    if (!this.zendeskSyncSource) {
      return undefined;
    }
    let id = `${this.zendeskSyncSource.source_type}-${this.zendeskSyncSource.source_id}`;
    return this.store.peekRecord('knowledge-hub/sync-source-wrapper', id);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FinAiAgent::Knowledge::Content': typeof Content;
  }
}
