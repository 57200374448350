/* import __COLOCATED_TEMPLATE__ from './folder.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { KNOWLEDGE_BLANK_QUERY_PARAMS } from 'embercom/lib/knowledge-hub/list-api';

interface Args {
  folderId: number;
}

interface Signature {
  Args: Args;
  Blocks: {
    default: [];
  };
  Element: Element;
}

export default class Folder extends Component<Signature> {
  // It's important to reset all query parameters in case of pre-existing filters for the page
  // we're going to transition the user to (as Ember.js uses sticky parameters).
  defaultParams = KNOWLEDGE_BLANK_QUERY_PARAMS;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FinAiAgent::Knowledge::ContentLink::Folder': typeof Folder;
    'fin-ai-agent/knowledge/content-link/folder': typeof Folder;
  }
}
