/* import __COLOCATED_TEMPLATE__ from './expired-sub.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import templateOnlyComponent from '@ember/component/template-only';

const ExpiredSub = templateOnlyComponent();
export default ExpiredSub;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ExpiredSub::ExpiredSub': typeof ExpiredSub;
    'expired-sub/expired-sub': typeof ExpiredSub;
  }
}
