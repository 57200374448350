/* import __COLOCATED_TEMPLATE__ from './status.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import Component from '@glimmer/component';
import type ContentImportSource from 'embercom/models/content-service/content-import-source';

interface Signature {
  Args: {
    source: ContentImportSource;
  };
}

export default class Status extends Component<Signature> {
  get simplifiedStatus() {
    if (!this.args.source.latestImportRun) {
      return 'sync';
    }

    return (
      {
        active: 'ready',
        deleted: 'error',
        deactivated: 'error',
        pending: 'sync',
        started: 'sync',
        completed: 'ready',
        failed: 'error',
        ingested: 'ready',
        processing: 'sync',
      }[this.args.source.latestImportRun.status] || 'ready'
    );
  }

  get isSyncing() {
    return this.simplifiedStatus === 'sync';
  }

  get hasError() {
    return this.simplifiedStatus === 'error';
  }

  get isReady() {
    return this.simplifiedStatus === 'ready';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FinAiAgent::Knowledge::ExternalContent::Status': typeof Status;
  }
}
