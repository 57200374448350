/* import __COLOCATED_TEMPLATE__ from './status.hbs'; */
/* RESPONSIBLE TEAM: team-ai-chatbot */
import templateOnlyComponent from '@ember/component/template-only';
import type SyncSourceWrapper from 'embercom/models/knowledge-hub/sync-source-wrapper';

interface Signature {
  Args: {
    source: SyncSourceWrapper;
  };
}

const ZendeskStatus = templateOnlyComponent<Signature>();
export default ZendeskStatus;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FinAiAgent::Knowledge::Zendesk::Status': typeof ZendeskStatus;
    'fin-ai-agent/knowledge/zendesk/status': typeof ZendeskStatus;
  }
}
